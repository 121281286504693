import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import SiteHeader from './layouts/SiteHeader';
import Footer from './layouts/Footer';
import CheckoutPage from './pages/CheckoutPage';
import Success from './pages/Success';

function Home () {
  return (
    <h1>Home Page</h1>
  )
}
function Profile () {
  return (
    <h1>Profile Page</h1>
  )
}
function App() {
  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
    <BrowserRouter>
    <SiteHeader />
    <Routes>
      <Route element={<HomePage/>} path='/' />
      {/* <Route element={<CheckoutPage />} path='/checkout' /> */}
      <Route element={<CheckoutPage />} path='/checkout/:cid' />
      <Route element={<Success />} path='/success/:booking_id' />
    </Routes>
    <Footer />
    </BrowserRouter>
    </div>
  );
}

export default App;
