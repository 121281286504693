import React from 'react'
import { Link } from "react-router-dom";
import logoImg from "../images/logo.png";
import logoLightImg from "../images/logo-light.png";

    const Logo = ({ className = "w-24" }) => {
        return (
          <Link
            to="/"
            className={`ttnc-logo inline-block text-primary-6000 focus:outline-none focus:ring-0 ${className}`}
          >
            <img
              className={`block max-h-12 ${logoLightImg ? "dark:hidden" : ""}`}
              src={logoImg}
              alt="Logo"
            />
          
          </Link>
        );
      };

      export default Logo;