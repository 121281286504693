import { GuestsObject } from "./Type";
import NcInputNumber from "./NcInputNumber";
import React, { useEffect, useState } from "react";

const GuestsInput = ({ defaultValue, onChange, className = "" }) => {

  return (
    <div className={`flex flex-col relative p-5 ${className}`}>
      <span className="mb-5 block font-semibold text-xl sm:text-2xl">
        {`Who's coming?`}
      </span>
      <NcInputNumber
        className="w-full"
        defaultValue={defaultValue.guestAdults}
        onChange={(value) => {{onChange({...defaultValue,  guestAdults: value})}}}

        max={20}
        label="Adults"
        desc="Ages 13 or above"
      />
      <NcInputNumber
        className="w-full mt-6"
        defaultValue={defaultValue.guestChildren}
        onChange={(value) => onChange({...defaultValue,  guestChildren: value})}
        max={20}
        label="Children"
        desc="Ages 2–12"
      />
  
    </div>
  );
};

export default GuestsInput;
