import { Tab } from "@headlessui/react";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import React, { useState, Fragment, useEffect } from "react";
import visaPng from "../images/vis.png";
import mastercardPng from "../images/mastercard.svg";
// import StartRating from "components/StartRating/StartRating";
import NcModal from "../components/NcModal";
import ModalSelectDate from "../layouts/ModalSelectDate";
import converSelectedDateToString from "../components/converSelectedDateToString";
import ModalSelectGuests from "../components/ModalSelectGuests";
import Label from "../components/Label";
import Input from "../components/Input";
import Textarea from "../components/Textarea";
import ButtonPrimary from "../components/Button/ButtonPrimary";
import ModalSelectRooms from "../components/ModalSelectRooms";
import { useNavigate, useParams } from "react-router-dom";
import { getCatInfo, submitBookingData } from "../app/api";

const CheckoutPage = ({ className = "" }) => {

  const navigate = useNavigate();
  const currentDate = new Date();
  const newdate = new Date();
  newdate.setDate(newdate.getDate()+3)
  const [startDate, setStartDate] = useState(currentDate);
  const [endDate, setEndDate] = useState(newdate);
  const {cid} = useParams();

  const [data, setData] = useState({})
  const [img, setImg] = useState({})

  const [guests, setGuests] = useState({
    guestAdults: 1,
    guestChildren: 0,
  });

  const [rooms, setRooms] = useState(1);

  function treatAsUTC(date) {
    var result = new Date(date);
    result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
    return result;
}

  function getDays () {
    var millisecondsPerDay = 24 * 60 * 60 * 1000;
    return (treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerDay;
  }

  const [user, setUser] = useState({
    name: "",
    phone_no:"",
    email:"",
    message: ""
  })
  const [formSub, setFormSub] = useState(false)

  const confirmBooking = async () => {
    setFormSub(true)
    let formData = {
      name: user.name,
      phone_no: user.phone_no,
      email: user.email,
      message: user.message,
      rooms: rooms,
      adult: guests.guestAdults,
      child: guests.guestChildren,
      sdate: startDate,
      edate: endDate,
      cat_id: cid,
      days: getDays(),
    }

    let res = await submitBookingData(formData);
    if(res.code == 200) {
      console.log('test')
      navigate(`${process.env.PUBLIC_URL}` +'/success/'+res.booking_id)
    }

  }
  const RenderSidebar = ({data, image}) => {
    // console.log(image[0].img) 
    return (
      <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
        <div className="flex flex-col sm:flex-row sm:items-center">
          <div className="flex-shrink-0 w-full sm:w-40">
            <div className="aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
              <img
                alt=""
                className="absolute inset-0 object-cover"
                sizes="200px"
                  src={(image.length) ? image[0].img : ''}
              />
            </div>
          </div>
          <div className="py-5 sm:px-5 space-y-3">
            <div>
              {/* <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                Hotel room in Tokyo, Japan
              </span> */}
              <span className="text-base font-medium mt-1 block">
                {data.category_name}
              </span>
            </div>
            <span className="block text-sm text-neutral-500 dark:text-neutral-400">
              {rooms} Room · {guests.guestAdults+guests.guestChildren} Guest
            </span>
            <div className="w-10 border-b border-neutral-200 dark:border-neutral-700"></div>
            {/* <StartRating /> */}
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <h3 className="text-2xl font-semibold">Price detail</h3>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Rs. {data.price*rooms} x {getDays()} days</span>
            <span>Rs. {(data.price*getDays())*rooms}</span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Service charge</span>
            <span>Rs. 0</span>
          </div>

          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Total</span>
            <span>Rs. {(data.price*getDays())*rooms}</span>
          </div>
        </div>
      </div>
    );
  };

  const renderMain = (data) => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          Confirm Your Booking
        </h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <div>
            <h3 className="text-2xl font-semibold">Your trip</h3>
            {/* <NcModal
              renderTrigger={(openModal) => (
                <span
                  onClick={() => openModal()}
                  className="block lg:hidden underline mt-1 cursor-pointer"
                >
                  View booking details
                </span>
              )}
              renderContent={renderSidebar}
              modalTitle="Booking details"
            /> */}
          </div>
          <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700 overflow-hidden z-10">
            <ModalSelectDate
              sdate={startDate}
              setSdate={setStartDate}
              edate={endDate}
              setEdate={setEndDate}
              renderChildren={({ openModal }) => (
                <button
                  onClick={openModal}
                  className="text-left flex-1 p-5 flex justify-between space-x-5 hover:bg-neutral-50 dark:hover:bg-neutral-800"
                  type="button"
                >
                  <div className="flex flex-col">
                    <span className="text-sm text-neutral-400">Date</span>
                    <span className="mt-1.5 text-lg font-semibold">
                      {converSelectedDateToString([startDate, endDate])}
                    </span>
                  </div>
                  <PencilSquareIcon className="w-6 h-6 text-neutral-6000 dark:text-neutral-400" />
                </button>
              )}
            />
            <ModalSelectRooms
              rooms={rooms}
              setRooms={setRooms}
              renderChildren={({ openModal }) => (
                <button
                  type="button"
                  onClick={openModal}
                  className="text-left flex-1 p-5 flex justify-between space-x-5 hover:bg-neutral-50 dark:hover:bg-neutral-800"
                >
                  <div className="flex flex-col">
                    <span className="text-sm text-neutral-400">Rooms</span>
                    <span className="mt-1.5 text-lg font-semibold">
                      <span className="line-clamp-1">
                       {rooms} {rooms > 1 ? 'Rooms' : 'Room'}
                      </span>
                    </span>
                  </div>
                  <PencilSquareIcon className="w-6 h-6 text-neutral-6000 dark:text-neutral-400" />
                </button>
              )}
            />  
            <ModalSelectGuests
              guests={guests}
              setGuests={setGuests}
              renderChildren={({ openModal }) => (
                <button
                  type="button"
                  onClick={openModal}
                  className="text-left flex-1 p-5 flex justify-between space-x-5 hover:bg-neutral-50 dark:hover:bg-neutral-800"
                >
                  <div className="flex flex-col">
                    <span className="text-sm text-neutral-400">Guests</span>
                    <span className="mt-1.5 text-lg font-semibold">
                      <span className="line-clamp-1">
                        {`${
                          (guests.guestAdults || 0) +
                          (guests.guestChildren || 0)
                        } Guests`}
                      </span>
                    </span>
                  </div>
                  <PencilSquareIcon className="w-6 h-6 text-neutral-6000 dark:text-neutral-400" />
                </button>
              )}
            />
          </div>
        </div>
        
        <div>
          <h3 className="text-2xl font-semibold">Customer Information</h3>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div>
          
          <div className="row">
            <div className="col-md-6">
            <div className="space-y-2">
                <Label>Customer Name {formSub}</Label>
                <Input type="text" name="name" onChange={(e)=>{ setUser((cinfo) => ({...cinfo, name:e.target.value}))}} placeholder="Enter Customer Name" />
                {(formSub && user.name == '') && <span style={{color:"#ff0000", fontSize:13, marginBottom:10}}>Please enter your full name</span>}
              </div>
              <div className="space-y-1">
                <Label>Contact No. </Label>
                <Input type="text" name="phone_no" onChange={(e)=>{ setUser((cinfo) => ({...cinfo, phone_no:e.target.value}))}}  placeholder="Enter Contact Number" />
                {(formSub && user.phone_no == '') && <span style={{color:"#ff0000", fontSize:13, marginBottom:10}}>Please enter your contact number</span>}
              </div>
            <div className="space-y-1">
                <Label>Email </Label>
                <Input type="email" name="email" onChange={(e)=>{ setUser((cinfo) => ({...cinfo, email:e.target.value}))}}  placeholder="Enter Email Address" />
                {(formSub && user.email == '') && <span style={{color:"#ff0000", fontSize:13, marginBottom:10}}>Please enter your email address</span>}
              </div>
              {/* <div className="space-y-1">
                <Label>Password </Label>
                <Input type="password" name="" defaultValue="" placeholder="Enter Password" />
              </div> */}
              <div className="space-y-1">
                <Label>Message for us </Label>
                <Textarea name="message" placeholder="Enter message for us" onChange={(e)=>{ setUser((cinfo) => ({...cinfo, message:e.target.value}))}}  />
                <span className="text-sm text-neutral-500 block">
                  Write a few sentences about yourself.
                </span>
              </div>
              </div>
          </div>

        </div>

        <div>
          <h3 className="text-2xl font-semibold">Payment Method</h3>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div>

          <div className="mt-6">
            <Tab.Group>
              <Tab.List className="flex my-5 gap-1">
                <Tab as={Fragment} >
                  {({ selected }) => (
                    <button
                      className={`px-4 py-1.5 sm:px-6 sm:py-2.5 rounded-full focus:outline-none ${
                        selected
                          ? "bg-neutral-800 dark:bg-neutral-200 text-white dark:text-neutral-900"
                          : "text-neutral-6000 dark:text-neutral-400 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      }`}
                    >
                      Pay at Hotel
                    </button>
                  )}
                </Tab>
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`px-4 py-1.5 sm:px-6 sm:py-2.5 rounded-full flex items-center justify-center focus:outline-none ${
                        selected
                          ? "bg-neutral-800 dark:bg-neutral-200 text-white dark:text-neutral-900"
                          : "text-neutral-6000 dark:text-neutral-400 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      }`}
                    >
                      <span className="mr-2.5">Credit card</span>
                      <img className="w-8" src={visaPng} alt="visa" />
                      <img className="w-8" src={mastercardPng} alt="mastercard" />
                    </button>
                  )}
                </Tab>
              </Tab.List>

              <Tab.Panels>
              
                <Tab.Panel className="space-y-5">
                  <div className="space-y-1">
                    You can pay at hotel desk
                  </div>
                  {/* <div className="space-y-1">
                    <Label>Email </Label>
                    <Input type="email" defaultValue="example@gmail.com" />
                  </div>
                  <div className="space-y-1">
                    <Label>Password </Label>
                    <Input type="password" defaultValue="***" />
                  </div>
                  <div className="space-y-1">
                    <Label>Message for author </Label>
                    <Textarea placeholder="..." />
                    <span className="text-sm text-neutral-500 block">
                      Write a few sentences about yourself.
                    </span>
                  </div> */}
                </Tab.Panel>
                <Tab.Panel className="space-y-5">
                  <div className="space-y-1">
                    <Label>Card number </Label>
                    <Input defaultValue="111 112 222 999" />
                  </div>
                  <div className="space-y-1">
                    <Label>Card holder </Label>
                    <Input defaultValue="JOHN DOE" />
                  </div>
                  <div className="flex space-x-5">
                    <div className="flex-1 space-y-1">
                      <Label>Expiration date </Label>
                      <Input type="date" defaultValue="MM/YY" />
                    </div>
                    <div className="flex-1 space-y-1">
                      <Label>CVC </Label>
                      <Input />
                    </div>
                  </div>
                  <div className="space-y-1">
                    <Label>Message for author </Label>
                    <Textarea placeholder="..." />
                    <span className="text-sm text-neutral-500 block">
                      Write a few sentences about yourself.
                    </span>
                  </div>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
            <div className="pt-8">
              <ButtonPrimary onClick={() => { confirmBooking() }}>Confirm Booking</ButtonPrimary>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const get_cat_info = async (cid) => {
    let res = await getCatInfo(cid);
    // console.log(res)
    if(res.code == 200) { 
      setData(res.data)
      setImg(res.images)
    }
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    get_cat_info(cid)
  }, [])
  return (
    <div className={`nc-CheckOutPagePageMain ${className}`}>
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
        {/* {cid} */}
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10">{renderMain(data)}</div>
        <div className="hidden lg:block flex-grow">
          {/* {renderSidebar(data)} */}
          <RenderSidebar data={data} image={img} />
          </div>
      </main>
    </div>
  );
};

export default CheckoutPage;
