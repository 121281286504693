import { Tab } from "@headlessui/react";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import React, { useState, Fragment, useEffect } from "react";
import visaPng from "../images/vis.png";
import mastercardPng from "../images/mastercard.svg";
// import StartRating from "components/StartRating/StartRating";
import NcModal from "../components/NcModal";
import ModalSelectDate from "../layouts/ModalSelectDate";
import converSelectedDateToString from "../components/converSelectedDateToString";
import ModalSelectGuests from "../components/ModalSelectGuests";
import Label from "../components/Label";
import Input from "../components/Input";
import Textarea from "../components/Textarea";
import ButtonPrimary from "../components/Button/ButtonPrimary";
import ModalSelectRooms from "../components/ModalSelectRooms";
import { useParams } from "react-router-dom";
import { getCatInfo, submitBookingData } from "../app/api";

const Success = ({ className = "" }) => {
  const {booking_id} = useParams();

  const [data, setData] = useState({})


  const renderMain = (data) => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h3 className="text-3xl font-semibold text-center" style={{color:"lightseagreen"}}>
          Your Booking is Confirmed!
        </h3>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <div>
            <h3 className="text-1xl font-semibold text-center pl-5 pr-5">Congratulations! Your Booking is Confirmed.<br/> Your Booking ID is #{booking_id}. <br/>Thank you for choosing us</h3>
    
          </div>
          {/* <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700 overflow-hidden z-10">

          </div> */}
        </div>
       
        <div>
        
          {/* <div className="mt-6">
            <div className="pt-8">
              <ButtonPrimary onClick={() => { confirmBooking() }}>Confirm Booking</ButtonPrimary>
            </div>
          </div> */}
        </div>
      </div>
    );
  };

//   const get_cat_info = async (cid) => {
//     let res = await getCatInfo(cid);
//     // console.log(res)
//     if(res.code == 200) { 
//       setData(res.data)
//       setImg(res.images)
//     }
//   }

//   useEffect(() => {
//     get_cat_info(cid)
//   }, [])
  return (
    <div className={`nc-CheckOutPagePageMain ${className}`}>
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
        {/* {cid} */}
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10">{renderMain(data)}</div>
        <div className="hidden lg:block flex-grow">
          </div>
      </main>
    </div>
  );
};

export default Success;
