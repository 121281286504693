import React, { useEffect, useRef, useState, Fragment } from "react";
import { Helmet } from "react-helmet-async";
import Header from "./Header";
import { Link, useLocation } from "react-router-dom";

const OPTIONS = {
  root: null,
  rootMargin: "0px",
  threshold: 1.0,
};

const PAGES_HIDE_HEADER_BORDER = [
  "/home-3",
  "/listing-car-detail",
  "/listing-experiences-detail",
  "/listing-stay-detail",
];

const SiteHeader = () => {
  const anchorRef = useRef(null);
  const [headers] = useState(["Header 1", "Header 2", "Header 3"]);
  const [homePages] = useState([
    { name: "Home Main", slug: "/" },
    { name: "Real Estate", slug: "/home-2" },
    { name: "Home 2", slug: "/home-3" },
  ]);
  const [headerSelected, setHeaderSelected] = useState("Header 1");
  const [isTopOfPage, setIsTopOfPage] = useState(window.pageYOffset < 5);
  const location = useLocation();

  useEffect(() => {
    const updateHeaderSelection = () => {
      if (location.pathname === "/home-2") setHeaderSelected("Header 2");
      else if (location.pathname === "/home-3") setHeaderSelected("Header 3");
      else setHeaderSelected("Header 1");
    };

    updateHeaderSelection();

    const observerCallback = (entries) => {
      entries.forEach(entry => setIsTopOfPage(entry.isIntersecting));
    };

    const observer = new IntersectionObserver(observerCallback, OPTIONS);
    if (anchorRef.current) observer.observe(anchorRef.current);

    return () => {
      observer.disconnect();
    };
  }, [location.pathname]);



  const renderHeader = () => {
    const headerClassName = PAGES_HIDE_HEADER_BORDER.includes(location.pathname) && !isTopOfPage ? "shadow-sm dark:border-b dark:border-neutral-700" : "";
    // switch (headerSelected) {
    //   case "Header 1":
    //     return <Header className={headerClassName} navType="MainNav1" />;
    //   case "Header 2":
    //     return <Header className={headerClassName} navType="MainNav2" />;
    //   case "Header 3":
    //     return <Header3 className={headerClassName} />;
    //   default:
    //     return <Header3 className={headerClassName} />;
    // }
    return  <Header className={headerClassName} navType="MainNav1" />;

  };

  return (
    <>
      <Helmet>
        <title>Chisfis || Booking React Template</title>
      </Helmet>
      {renderHeader()}
      <div ref={anchorRef} className="h-1 absolute invisible"></div>
    </>
  );
};

export default SiteHeader;
