
// import __megamenu from "./jsons/__megamenu.json";

function ncNanoId(prefix = "nc_") {
    return (
      prefix + Date.now() + "_" + Math.floor(Math.random() * 9999999999)
    );
  }


export const NAVIGATION_DEMO = [
  {
    id: ncNanoId(),
    href: "/",
    name: "Home",
  },
  {
    id: ncNanoId(),
    href: "/about",
    name: "About Us",
  },
  {
    id: ncNanoId(),
    href: "/privacy",
    name: "Privacy Policy",
  },
  {
    id: ncNanoId(),
    href: "/contact",
    name: "Contact Us",
  },
];
