import { create } from 'apisauce'

const api = create({
    baseURL: 'https://www.traffik99.com/api',
    headers: {
        "X-API-KEY": 'Qr17OjTfsd67nP',
        // "Authorization": acesspass,
        "Access-Control-Allow-Origin": true,
      },
  })

//   const loginAdmin = async (username, password) => {
//     const res = await api.post("/login", {
//       username: username,
//       password: password,
//     });
//     return res.data;
//   };

  const getRoomCats = async (data) =>{
    const res = await api.post('/get_cat_list', data);
    return res.data;
  }

  const getCatInfo = async (cid) => {
    const res = await api.post('/cat_detail', {cid:cid});
    return res.data;
  }

  const submitBookingData = async (data) => {
    let res = await api.post('/booking', data);
    return res.data;
  }

  export {
    getRoomCats,
    getCatInfo,
    submitBookingData

  }