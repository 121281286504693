import { GuestsObject } from "./Type";
import NcInputNumber from "./NcInputNumber";
import React, { useEffect, useState } from "react";

const RoomInput = ({ defaultValue, onChange, className = "" }) => {
  const [roomVal, setRoomVal] = useState(defaultValue);
  const [guestChildrenInputValue, setGuestChildrenInputValue] = useState(1);
  const [guestInfantsInputValue, setGuestInfantsInputValue] = useState(
    defaultValue?.guestInfants || 0
  );

  const handleChangeData = (value, type) => {
 
  };

  return (
    <div className={`flex flex-col relative p-5 ${className}`}>
      <span className="mb-5 block font-semibold text-xl sm:text-2xl">
        {`Choose Your Room`}
      </span>
      <NcInputNumber
        className="w-full"
        defaultValue={roomVal}
        onChange={(value) => {
          onChange(value)
        }}
        max={20}
        min={1}
        label="Select Rooms"
        desc="Minimum 1 room required"
      />
    </div>
  );
};

export default RoomInput;
