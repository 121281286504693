import React, { useEffect, useState } from "react";
// import { DEMO_STAY_LISTINGS } from "data/listings";
import ButtonPrimary from "../components/Button/ButtonPrimary";
// import HeaderFilter from "./HeaderFilter";
import StayCard from "../components/StayCard";
import { getRoomCats } from "../app/api";

// OTHER DEMO WILL PASS PROPS
// const DEMO_DATA = DEMO_STAY_LISTINGS.filter((_, i) => i < 8);
const DEMO_DATA = [];

const SectionGridFeaturePlaces = ({
  stayListings = DEMO_DATA,
  gridClass = "",
  heading = "Featured places to stay",
  subHeading = "Popular places to stay that Chisfis recommends for you",
  headingIsCenter,
  tabs = ["New York", "Tokyo", "Paris", "London"],
}) => {
  // const renderCard = (stay) => {
  //   return <StayCard key={stay.id} data={stay} />;
  // };

  const [data, setData] = useState([]);

  const getCatList = async () => {
    let res = await getRoomCats();
    console.log(res)
    if(res.code == 200) {
      setData(res.data);
    }
  }

  useEffect(()=> {
    getCatList();
  },[])
  return (
    <div className="nc-SectionGridFeaturePlaces relative">
      {/* <HeaderFilter
        tabActive={"New York"}
        subHeading={subHeading}
        tabs={tabs}
        heading={heading}
        onClickTab={() => {}}
      /> */}
      <div
        className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ${gridClass}`}
      >
        {data.map((item, index) => {
          return <StayCard key={index} data={item} />
        })}
        {/* {stayListings.map((stay) => renderCard(stay))} */}
      </div>
      {/* <div className="flex mt-16 justify-center items-center">
        <ButtonPrimary loading>Show me more</ButtonPrimary>
      </div> */}
    </div>
  );
};

export default SectionGridFeaturePlaces;
