import React from "react";
import MainNav from "./MainNav";

const Header = ({ navType = "MainNav1", className = "" }) => {
  const renderNav = () => {
        return <MainNav />;
  };

  return (
    <div
      className={`nc-Header sticky top-0 w-full left-0 right-0 z-40 nc-header-bg ${className}`}
    >
      {renderNav()}
    </div>
  );
};

export default Header;
